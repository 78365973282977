var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "link",
          expression: "widget.compType === 'link'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_link_text") } },
          [
            _c("el-input", {
              staticClass: "input",
              model: {
                value: _vm.widget.properties.text,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "text", $$v)
                },
                expression: "widget.properties.text",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_type") } },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: _vm.$t("lang_please_select") },
                model: {
                  value: _vm.widget.properties.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "type", $$v)
                  },
                  expression: "widget.properties.type",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "primary", value: "primary" },
                }),
                _c("el-option", {
                  attrs: { label: "success", value: "success" },
                }),
                _c("el-option", {
                  attrs: { label: "warning", value: "warning" },
                }),
                _c("el-option", {
                  attrs: { label: "danger", value: "danger" },
                }),
                _c("el-option", { attrs: { label: "info", value: "info" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_url") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.href,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "href", $$v)
                },
                expression: "widget.properties.href",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_new_window") } },
          [
            _c("el-switch", {
              on: { change: _vm.handlerChangeTargetValue },
              model: {
                value: _vm.isNewWindow,
                callback: function ($$v) {
                  _vm.isNewWindow = $$v
                },
                expression: "isNewWindow",
              },
            }),
          ],
          1
        ),
      ],
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_operation_status") } },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("lang_please_select"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.operation,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "operation", $$v)
                },
                expression: "widget.properties.operation",
              },
            },
            _vm._l(_vm.statusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.label, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }